import { Plural, Trans } from '@lingui/macro'
import { DatabaseSchema } from 'common/databaseSchema'
import { demoFacilityName } from 'common/demo'
import { DateActivity } from 'common/types'
import React, { useMemo, useState } from 'react'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { TimeRange } from 'shared/types/timeRange'
import { DateString, FacilityName, Room } from 'shared/types/utils'
import { ActivityGraphs } from './ActivityGraphs'
import { Title } from './components/Text'
import { useMergedFirebase } from './hooks/useMergedFirebase'

interface Props {
  dates: DateString[]
  rooms: Room[]
  facilityName: FacilityName
  monitoringTimeRange: TimeRange
}

export const ActivityReport: React.FC<Props> = ({
  dates,
  rooms,
  facilityName,
  monitoringTimeRange,
}) => {
  const refActivity = useMemo(
    () =>
      dates.reduce<Record<DateString, string>>((acc, date, index) => {
        if (facilityName === demoFacilityName)
          acc[date] = `demo/activity/${index}`
        else acc[date] = `activity/${facilityName}/${date}`
        return acc
      }, {}),
    [dates, facilityName],
  )

  const {
    data: activity,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'activity/${string}/${string}', DatabaseSchema>
  >(refActivity)

  return loading ? (
    <div className="my-24 text-center">
      <Trans>Chargement...</Trans>
    </div>
  ) : error ? (
    <div className="my-24 text-center">
      <Trans>Erreur</Trans>
    </div>
  ) : rooms.length === 0 ? (
    <div className="my-24 text-center">
      <Trans>Aucune donnée</Trans>
    </div>
  ) : (
    <ActivityReportWithData
      dates={dates}
      rooms={rooms}
      monitoringTimeRange={monitoringTimeRange}
      activity={activity}
    />
  )
}

interface ActivityProps {
  dates: DateString[]
  rooms: Room[]
  monitoringTimeRange: TimeRange
  activity: DateActivity
}

export const ActivityReportWithData: React.FC<ActivityProps> = ({
  dates,
  rooms,
  monitoringTimeRange,
  activity,
}) => {
  const [selectedRoom, setSelectedRoom] = useState(rooms[0])

  return (
    <div className="w-0 min-w-full">
      <Title>
        <Plural
          value={rooms.length}
          one="# chambre surveillée :"
          other="# chambres surveillées :"
        />
      </Title>
      <div className="my-6 flex w-full flex-row flex-wrap justify-around gap-2">
        {rooms.map((room) => (
          <button
            key={room}
            className={`w-12 min-w-max rounded-md px-1 py-1 hover:bg-blue-200 md:w-16 md:px-2 ${
              room === selectedRoom ? 'bg-blue-300' : 'bg-slate-100'
            }`}
            onClick={() => setSelectedRoom(room)}
          >
            {room}
          </button>
        ))}
      </div>
      <ActivityGraphs
        dates={dates}
        monitoringTimeRange={monitoringTimeRange}
        activity={activity}
        selectedRoom={selectedRoom}
      />
    </div>
  )
}
