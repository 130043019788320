import { Trans } from '@lingui/macro'
import { DatabaseSchema } from 'common/databaseSchema'
import { demoFacilityName } from 'common/demo'
import React, { useMemo, useState } from 'react'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { alertCategory } from 'shared/types/alert'
import { Zones } from 'shared/types/fleet'
import {
  Alerts,
  DateAlerts,
  Facility,
  RoomAlerts,
} from 'shared/types/reporting'
import { DateString, FacilityName, FirebaseKey, Room } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { alertDateTime } from 'shared/utils/time'
import { alertIsInZone } from 'shared/utils/zone'
import { AlertsByDate } from './AlertsByDate'
import { AlertsByRoom } from './AlertsByRoom'
import { Period, PeriodSelector, filterPeriodAlerts } from './PeriodSelector'
import { ZoneSelector } from './ZoneSelector'
import { useMergedFirebase } from './hooks/useMergedFirebase'

type Props = {
  dates: DateString[]
  rooms: Room[]
  facilityName: FacilityName
  facility: Facility
  showPeriodSelector: boolean
}

export const AlertsReport: React.FC<Props> = ({
  dates,
  rooms,
  facilityName,
  facility,
  showPeriodSelector,
}) => {
  const zones = facility.zones ?? {}

  // Load all alerts, by date
  const alertsRefPathsMap = useMemo(
    () =>
      dates.reduce<Record<string, string>>((acc, date, index) => {
        if (facilityName === demoFacilityName)
          acc[date] = `demo/alerts/${index}`
        else acc[date] = `alerts/${facilityName}/${date}`
        return acc
      }, {}),
    [dates, facilityName],
  )

  const {
    data: alerts,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'alerts/${string}/${string}', DatabaseSchema>
  >(alertsRefPathsMap)

  return loading ? (
    <div className="my-24 text-center">
      <Trans>Chargement...</Trans>
    </div>
  ) : error ? (
    <div className="my-24 text-center">
      <Trans>Erreur</Trans>
    </div>
  ) : (
    <DataAlertReport
      dates={dates}
      rooms={rooms}
      facilityName={facilityName}
      alerts={alerts}
      zones={zones}
      showPeriodSelector={showPeriodSelector}
    />
  )
}

interface DataAlertReportProps {
  dates: DateString[]
  rooms: Room[]
  alerts: DateAlerts
  facilityName: FacilityName
  zones: Zones
  showPeriodSelector: boolean
}

const DataAlertReport: React.FC<DataAlertReportProps> = ({
  dates,
  rooms,
  alerts,
  facilityName,
  zones,
  showPeriodSelector,
}) => {
  const [selectedZone, setSelectedZone] = useState<FirebaseKey | undefined>()
  const [selectedPeriod, setSelectedPeriod] = useState<Period>()

  const zoneAlerts = useMemo(() => {
    if (selectedZone === undefined) return alerts

    const zone = zones[selectedZone]
    return Object.entries(alerts).reduce<DateAlerts>(
      (acc, [date, roomAlerts]) => {
        acc[date] = Object.entries(roomAlerts ?? {}).reduce<RoomAlerts>(
          (acc2, [room, alerts]) => {
            const roomAlerts = Object.entries(alerts ?? {}).reduce<Alerts>(
              (acc3, [key, alert]) => {
                if (
                  alertIsInZone(
                    room,
                    alertDateTime(alert.date),
                    alertCategory(alert.type),
                    zone,
                  )
                )
                  acc3[key] = alert
                return acc3
              },
              {},
            )
            if (!isObjectEmpty(roomAlerts)) acc2[room] = roomAlerts
            return acc2
          },
          {},
        )
        return acc
      },
      {},
    )
  }, [alerts, zones, selectedZone])

  const filteredAlerts = useMemo(() => {
    if (selectedPeriod === undefined) return zoneAlerts

    return Object.entries(zoneAlerts).reduce<DateAlerts>(
      (acc, [date, roomAlerts]) => {
        acc[date] = Object.entries(roomAlerts ?? {}).reduce<RoomAlerts>(
          (acc2, [room, alerts]) => {
            acc2[room] = filterPeriodAlerts(alerts, selectedPeriod)
            return acc2
          },
          {},
        )
        return acc
      },
      {},
    )
  }, [zoneAlerts, selectedPeriod])

  const officialZones = Object.fromEntries(
    Object.entries(zones).filter(([, zone]) => !zone.isHoliday),
  )

  return (
    <>
      {facilityName === demoFacilityName && (
        <div className="text-center text-sm">
          <Trans>
            Ces données ont été choisies aléatoirement. Seuls les 3 derniers
            jours peuvent être écoutés.
          </Trans>
        </div>
      )}
      <div className="flex flex-col flex-wrap justify-around gap-4 py-4 md:flex-row">
        <ZoneSelector
          zones={officialZones}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
        />
        {showPeriodSelector && (
          <PeriodSelector
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
          />
        )}
      </div>
      <AlertsByDate dates={dates} alerts={filteredAlerts} />
      {rooms.length > 0 && (
        <AlertsByRoom rooms={rooms} alerts={filteredAlerts} />
      )}
    </>
  )
}
