import { Trans } from '@lingui/macro'
import { Activity, DateData, DayActivity, HourActivity } from 'common/types'
import React, { useMemo, useState } from 'react'
import { TimeRange } from 'shared/types/timeRange'
import { DateString } from 'shared/types/utils'
import { DateGraph } from './DateGraph'
import { DayGraph } from './DayGraph'
import { Period, PeriodSelector } from './PeriodSelector'
import {
  ActivityType,
  activities,
  activityData,
  hiddenCommonRoomsActivities,
} from './utils/activity'

interface Props {
  dates: DateString[]
  dateActivity: DateData<HourActivity>
  monitoringTimeRange: TimeRange
  selectedActivity: ActivityType | undefined
  isCommonRoom: boolean
}

export const RoomActivityGraph: React.FC<Props> = ({
  dates,
  dateActivity,
  monitoringTimeRange,
  selectedActivity,
  isCommonRoom,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>()

  const filteredActivity = useMemo(() => {
    if (!isCommonRoom) return dateActivity // no filtering

    return Object.entries(dateActivity).reduce<DateData<HourActivity>>(
      (acc, [date, hourActivity]) => {
        acc[date] = Object.entries(hourActivity ?? {}).reduce<HourActivity>(
          (acc, [hour, activity]) => {
            acc[hour] = Object.entries(activity ?? {}).reduce<Activity>(
              (acc, [activityType, count]) => {
                const activity = activityType as ActivityType
                // Do not show icon related activities, like in rooms
                if (!hiddenCommonRoomsActivities.includes(activity))
                  acc[activity] = count
                return acc
              },
              {},
            )
            return acc
          },
          {},
        )
        return acc
      },
      {},
    )
  }, [dateActivity, isCommonRoom])

  const averageActivityByHour = useMemo(() => {
    const result: HourActivity = {}
    const nbDays = dates.length

    Object.values(filteredActivity).forEach((hourActivity) => {
      Object.entries(hourActivity ?? {}).forEach(([hourStr, activity]) => {
        const hour = parseInt(hourStr, 10)
        result[hour] ??= {}
        const hourResult = result[hour] as Activity
        Object.entries(activity ?? {}).forEach(([activityType, count]) => {
          const activity = activityType as ActivityType
          hourResult[activity] = (hourResult[activity] ?? 0) + count / nbDays
        })
      })
    })

    return result
  }, [filteredActivity, dates])

  const useCalculateActivitySum = (
    dates: DateString[],
    dateActivity: DateData<HourActivity>,
    startHour: number,
    endHour: number,
  ) => {
    return useMemo(() => {
      const result: DayActivity = {}

      dates.forEach((date, index) => {
        const dayActivity = result[date] || {}
        result[date] = dayActivity
        const currentDayHours = dateActivity[date] || {}

        if (startHour < endHour) {
          for (let hour = startHour; hour < endHour; hour++) {
            const activity = currentDayHours[hour]
            if (activity) {
              Object.entries(activity).forEach(([activityType, count]) => {
                const activity = activityType as ActivityType
                dayActivity[activity] = (dayActivity[activity] || 0) + count
              })
            }
          }
        } else {
          for (let hour = startHour; hour < 24; hour++) {
            const activity = currentDayHours[hour]
            if (activity) {
              Object.entries(activity).forEach(([activityType, count]) => {
                const activity = activityType as ActivityType
                dayActivity[activity] = (dayActivity[activity] || 0) + count
              })
            }
          }
          const nextDayHours = dateActivity[dates[index + 1]] || {}
          for (let hour = 0; hour < endHour; hour++) {
            const activity = nextDayHours[hour]
            if (activity) {
              Object.entries(activity).forEach(([activityType, count]) => {
                const activity = activityType as ActivityType
                dayActivity[activity] = (dayActivity[activity] || 0) + count
              })
            }
          }
        }
      })

      return result
    }, [dates, dateActivity, startHour, endHour]) // Dependencies are only the start and end hours
  }

  const activitySumByEntireDay = useCalculateActivitySum(
    dates,
    filteredActivity,
    9,
    9,
  )
  const activitySumByNight = useCalculateActivitySum(
    dates,
    filteredActivity,
    19,
    9,
  )
  const activitySumByDay = useCalculateActivitySum(
    dates,
    filteredActivity,
    9,
    19,
  )

  const maxActivityCount = 375 // max count in one hour

  return (
    <div className="flex flex-col gap-2">
      <Trans>🕑 Journée type</Trans>
      <div className="text-xs">
        <Trans>
          Pour chaque heure de la journée, temps moyen passé à chaque activité
          (sur les 3 dernière semaines).
        </Trans>
      </div>
      <DayGraph
        hourValues={averageActivityByHour}
        maxValue={maxActivityCount / 1.6}
        labels={activities}
        selectedLabel={selectedActivity}
        graphConfig={activityData}
        monitoringTimeRange={monitoringTimeRange}
      />
      <div />
      <Trans>📅 Évolution de l'activité sur 3 semaines</Trans>
      <div className="text-xs">
        <Trans>
          Évolution sur 3 semaines du temps passé à différentes activités.
          <br />
          Vous pouvez choisir d'afficher les activités pour la journée (de 9h à
          19h), la nuit (de 19h à 9h) ou les deux.
          <br />
          Les échelles ont été ajustées pour chaque activité afin de faciliter
          la comparaison visuelle. Ainsi, le temps de sommeil et de télévision
          détecté peut atteindre un maximum de 12h/jour, tandis que les autres
          activités sont plafonnées à 6h/jour.
        </Trans>
      </div>
      <PeriodSelector
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
      />
      <DateGraph
        dates={dates}
        dateValues={
          selectedPeriod === 'NIGHT'
            ? activitySumByNight
            : selectedPeriod === 'DAY'
              ? activitySumByDay
              : activitySumByEntireDay
        }
        maxValue={(maxActivityCount * 24) / 2.2}
        labels={activities}
        selectedLabel={selectedActivity}
        graphConfig={activityData}
      />
    </div>
  )
}
