import { Trans } from '@lingui/macro'
import React, { useCallback, useMemo } from 'react'
import { Centered } from 'shared/components/Centered'
import { ContactType, Contacts as ContactsType } from 'shared/types/live'
import { FacilityName } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { Title } from './components/Text'
import { useFirebase } from './hooks/useFirebase'

interface Props {
  facilityName: FacilityName
}

export const Contacts: React.FC<Props> = ({ facilityName }) => {
  const { data, loading, error } = useFirebase(`contacts/${facilityName}`)

  return loading ? (
    <Centered>
      <Trans>Chargement...</Trans>
    </Centered>
  ) : error ? (
    <Centered>
      <Trans>Erreur</Trans>
    </Centered>
  ) : isObjectEmpty(data ?? {}) ? (
    <div className="max-w-xl px-4">
      <Trans>
        La fonctionnalité de téléphonie n'est pas activée dans votre
        établissement. Contactez-nous pour en savoir plus et la mettre en place.
      </Trans>
    </div>
  ) : (
    <Contacts_ contacts={data || {}} />
  )
}

export const Contacts_: React.FC<{ contacts: ContactsType }> = ({
  contacts,
}) => {
  const extractContacts = useCallback(
    (contactType: ContactType) => {
      return Object.values(contacts)
        .filter(({ type }) => type === contactType)
        .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
    },
    [contacts],
  )

  const userContacts = useMemo(() => extractContacts('user'), [extractContacts])

  const externalContacts = useMemo(
    () => extractContacts('external'),
    [extractContacts],
  )

  return (
    <>
      {userContacts.length > 0 ? (
        <>
          <Title>
            <Trans>Contacts internes</Trans>
          </Title>
          <div className="w-full max-w-screen-md py-4">
            <div className="hidden font-bold sm:flex">
              <div className="w-1/3">
                <Trans>Nom</Trans>
              </div>
              <div className="w-1/3">
                <Trans>Zone</Trans>
              </div>
              <div className="w-1/3 text-center">
                <Trans>Numéro</Trans>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              {userContacts.map((contact) => (
                <div
                  className="mt-2 flex flex-col py-4 sm:flex-row sm:py-0 "
                  key={contact.name}
                >
                  <div className="text-center font-bold sm:w-1/3 sm:text-left sm:font-normal">
                    <div>{contact.name}</div>
                  </div>
                  <div className="text-center sm:w-1/3 sm:text-left">
                    {contact.zone ? (
                      contact.zone
                    ) : (
                      <i>
                        <Trans>Toutes les chambres</Trans>
                      </i>
                    )}
                  </div>
                  {PhoneNumber(contact.phoneNumber)}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div>
          <Trans>Aucun contact</Trans>
        </div>
      )}
      {externalContacts.length > 0 && (
        <>
          <Title>
            <Trans>Contacts extérieurs</Trans>
          </Title>
          <div className="w-full max-w-screen-md py-4">
            <div className="hidden font-bold sm:flex">
              <div className="w-1/3">
                <Trans>Nom</Trans>
              </div>
              <div className="w-1/3"></div>
              <div className="w-1/3 text-center">
                <Trans>Numéro</Trans>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              {externalContacts.map((contact) => (
                <div
                  className="mt-2 flex flex-col py-4 sm:flex-row sm:py-0 "
                  key={contact.name}
                >
                  <div className="text-center font-bold sm:w-1/3 sm:text-left sm:font-normal">
                    <div>{contact.name}</div>
                  </div>
                  <div className="text-center sm:w-1/3 sm:text-left"></div>
                  {PhoneNumber(contact.phoneNumber)}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

// Using toString in case phoneNumber is considered a number
const PhoneNumber = (phoneNumber: string) => (
  <a
    className="flex h-6 min-w-32 items-center justify-center rounded-full bg-teal-700/25 px-2 py-1 tabular-nums text-black sm:w-1/3 sm:text-right"
    href={`tel:${phoneNumber}`}
  >
    {(phoneNumber ?? '?').toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ')}
  </a>
)
