import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ChangeLogEntry } from 'common/types'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Dialog } from 'shared/components/Dialog'
import { CHANGELOG } from './changelog.config'
import { Button } from './components/Button'

const lastWhatsNewTimeStorageKey = `lastWhatsNewTime`

function formatTsDateShort(ts: number) {
  return DateTime.fromMillis(ts).toLocaleString({
    weekday: 'short',
    month: 'long',
    day: 'numeric',
  })
}

export const WhatsNew: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [fromTime, setFromTime] = useState<number | undefined>()

  const sortedEntries = Object.values(CHANGELOG).sort(
    (entryA, entryB) => entryB.timestamp - entryA.timestamp,
  )

  useEffect(() => {
    if (sortedEntries.length === 0) return

    const lastWhatsNewTime =
      localStorage.getItem(lastWhatsNewTimeStorageKey) ?? '0'
    const lastTime = Number.parseInt(lastWhatsNewTime)

    const hasNewEntries = sortedEntries.some(
      (entry) => entry.timestamp > lastTime,
    )

    if (hasNewEntries) {
      setIsVisible(true)
      setFromTime(lastTime)
    }

    localStorage.setItem(lastWhatsNewTimeStorageKey, Date.now().toString())
  }, [sortedEntries])

  const entries = fromTime
    ? sortedEntries.filter((entry) => entry.timestamp > fromTime)
    : sortedEntries

  return (
    <>
      <Button
        onClick={() => setIsVisible(true)}
        disabled={entries.length === 0}
      >
        <Trans>Nouveautés</Trans>
      </Button>
      {isVisible && (
        <WhatsNewDialog
          entries={entries}
          hasMore={entries.length < sortedEntries.length}
          onClose={() => setIsVisible(false)}
          showAll={() => setFromTime(undefined)}
        />
      )}
    </>
  )
}

type DialogProps = {
  entries: ChangeLogEntry[]
  hasMore: boolean
  onClose: () => void
  showAll: () => void
}

const WhatsNewDialog: React.FC<DialogProps> = ({
  entries,
  hasMore,
  onClose,
  showAll,
}) => {
  const { _: lingui } = useLingui()

  return (
    <Dialog onClose={onClose}>
      <div className="relative rounded-md bg-white p-4">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row justify-between">
            <div className="text-xl">
              <Trans>Nouveautés</Trans>
            </div>
            <div
              className="absolute right-2 top-2 flex h-10 w-10 cursor-pointer flex-row items-center justify-center rounded-full bg-blue-500 p-2 text-3xl text-white hover:bg-blue-700"
              onClick={onClose}
            >
              &times;
            </div>
          </div>
          <div className="text-slate-600">
            <Trans>
              Voici les dernières fonctionnalités ajoutées à notre service :
            </Trans>
          </div>
          <div className="grid max-h-[70vh] grid-cols-5 gap-x-4 gap-y-6 overflow-auto">
            {entries.map((entry) => (
              <React.Fragment key={lingui(entry.title)}>
                <div className="pt-0.5 text-right text-slate-500">
                  {formatTsDateShort(entry.timestamp)}
                </div>
                <div className="col-span-4 flex flex-col gap-1">
                  <div className="text-lg font-bold">{lingui(entry.title)}</div>
                  <p className="whitespace-pre-wrap">{lingui(entry.text)}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
          {hasMore && (
            <div className="flex flex-row justify-end">
              <Button onClick={showAll}>
                <Trans>Voir tout l'historique</Trans>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}
