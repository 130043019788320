import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { Alerts } from 'shared/types/reporting'
import { TimeRange } from 'shared/types/timeRange'
import { alertDateTime } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'

const DAY = { label: msg`Journée`, value: 'DAY' as Period }
const NIGHT = { label: msg`Nuit`, value: 'NIGHT' as Period }
const ALL = { label: msg`Tout`, value: undefined }
const periods = [ALL, DAY, NIGHT]

export type Period = 'DAY' | 'NIGHT' | undefined

const nightTimeRange: TimeRange = { start: '19:00', end: '07:00' }

export function filterPeriodAlerts(
  alerts: Alerts,
  selectedPeriod: 'DAY' | 'NIGHT',
) {
  return Object.entries(alerts).reduce<Alerts>((acc, [key, alert]) => {
    const alertDate = alertDateTime(alert.date)
    const isNightAlert = isWithinTimeRange(alertDate, nightTimeRange)
    if (
      (selectedPeriod === NIGHT.value && isNightAlert) ||
      (selectedPeriod === DAY.value && !isNightAlert)
    )
      acc[key] = alert
    return acc
  }, {})
}

interface Props {
  selectedPeriod: Period
  setSelectedPeriod: (_: Period) => void
}

export const PeriodSelector: React.FC<Props> = ({
  selectedPeriod,
  setSelectedPeriod,
}) => {
  const { _: lingui } = useLingui()

  return (
    <div className="flex flex-row flex-wrap justify-center">
      {periods.map((period, index) => (
        <button
          key={index}
          className={`px-4 py-2 ${
            selectedPeriod === period.value
              ? 'bg-blue-500 text-white'
              : 'bg-gray-300 text-gray-700'
          } ${
            index === 0
              ? 'rounded-l'
              : index === periods.length - 1
                ? 'rounded-r'
                : ''
          }`}
          onClick={() => setSelectedPeriod(period.value)}
        >
          {lingui(period.label)}
        </button>
      ))}
    </div>
  )
}
