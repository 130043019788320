import { Trans } from '@lingui/macro'
import { demoFacilityName } from 'common/demo'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { Facilities } from 'shared/types/reporting'
import { DateString, FacilityName, Room } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { getPreviousDates } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { isUserAdmin } from './admin'
import { get, update } from './firebaseMethods'
import { Footer } from './Footer'
import { Header } from './Header'
import { useAuth } from './hooks/useAuth'
import { Report } from './Report'

function reportDates() {
  const today = DateTime.now().toISODate()
  return getPreviousDates(today, 21)
}

function demoRooms() {
  const rooms = []
  for (let room = 100; room <= 123; room++) rooms.push(room.toString(10))
  for (let room = 200; room <= 217; room++) rooms.push(room.toString(10))
  return rooms
}

async function getRooms(facilityName: FacilityName, dates: DateString[]) {
  if (facilityName === demoFacilityName) return demoRooms()
  const dateRooms = await Promise.all(
    dates.map((date) => get(`rooms/${facilityName}/${date}`)),
  )
  const roomsSet = new Set<Room>()
  Object.entries(dateRooms).map(([_date, rooms]) => {
    Object.keys(rooms ?? {}).map((room) => roomsSet.add(room))
  })
  return [...roomsSet].sort(collator.compare)
}

export function ReportLoader() {
  const { data: user } = useAuth()

  // Using null to mean loading in progress
  const [facilityName, setFacilityName] = useState<FacilityName | null>(null)
  const [facilities, setFacilities] = useState<Facilities>({})
  const [rooms, setRooms] = useState<Room[] | undefined>()
  const [isAdmin, setIsAdmin] = useState(false)
  const dates = useMemo(reportDates, [])

  useEffect(() => {
    async function runASync() {
      if (user === null) return

      const dbUser = await get(`users/${user.uid}`)

      let facilities: Facilities

      // dbUser might be undefined if the user is an admin
      if (dbUser) {
        const facilityNames = Object.keys(dbUser.facilityIds ?? {})

        const facilitiesArray = await Promise.all(
          facilityNames.map((facilityName) =>
            get(`facilities/${facilityName}`),
          ),
        )

        facilities = facilityNames.reduce<Facilities>(
          (acc, facilityName, index) => {
            acc[facilityName] = facilitiesArray[index]
            return acc
          },
          {},
        )
      } else {
        const isAdmin = await isUserAdmin(user.uid)
        if (!isAdmin) {
          facilities = {} // Access denied
        } else {
          facilities = await get(`facilities`)
          setIsAdmin(isAdmin)
        }
      }

      const facilityName = Object.keys(facilities)[0]

      setFacilityName(facilityName)
      setFacilities(facilities)
    }

    runASync()
  }, [user])

  useEffect(() => {
    async function runASync() {
      if (!facilityName) return
      setRooms(await getRooms(facilityName, dates))
    }
    runASync()
  }, [facilityName, dates])

  useEffect(() => {
    if (!facilityName || user === null || isAdmin) return
    update(`users/${user.uid}`, {
      lastVisitFacilityId: facilityName,
      lastVisitTimestamp: serverTimestamp(),
    })
  }, [facilityName, isAdmin, user])

  const changeFacilityName = useCallback(
    (newFacilityName: FacilityName) => {
      if (newFacilityName === facilityName) return
      // Make sure previous rooms are not used when changing facility
      setFacilityName(newFacilityName)
      setRooms(undefined)
    },
    [facilityName],
  )

  return (
    <div>
      {facilityName === null ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : isObjectEmpty(facilities) || !facilities[facilityName] ? (
        <Centered fullScreen>
          <Trans>Accès refusé</Trans>
        </Centered>
      ) : rooms === undefined ? (
        <Centered fullScreen>
          <Trans>Chargement...</Trans>
        </Centered>
      ) : (
        <div className="relative mx-auto mb-8 mt-0 min-w-min px-0 md:mt-8 md:max-w-7xl md:px-12">
          <Header
            dates={dates}
            facilityName={facilityName}
            setFacilityName={changeFacilityName}
            facilities={facilities}
            isAdmin={isAdmin}
          />
          <div className="px-4 md:px-0">
            <Report
              key={facilityName}
              dates={dates}
              facility={facilities[facilityName]}
              facilityName={facilityName}
              rooms={rooms}
            />
            <Footer />
          </div>
        </div>
      )}
    </div>
  )
}
