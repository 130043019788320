import { Trans, t } from '@lingui/macro'
import { DeleteUserRequestData, EditUserRequestData } from 'common/httpCall'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Trash2 as Trash, TriangleAlert } from 'lucide-react'
import React, { useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { FacilityName, UserId } from 'shared/types/utils'
import { User } from './common/user'
import { app } from './firebase'

interface Props {
  uid: UserId
  user: User
  facilityNames: FacilityName[]
  onError: (message: string) => void
  onClose: () => void
}

const NO_FACILITY = ''

export const EditUserForm: React.FC<Props> = ({
  uid,
  user,
  facilityNames,
  onError,
  onClose,
}) => {
  const [newFacilityName, setNewFacilityName] =
    useState<FacilityName>(NO_FACILITY)

  const functions = getFunctions(app, DEFAULT_REGION)
  const editUser = httpsCallable<EditUserRequestData, void>(
    functions,
    'toggleUserFacility',
  )
  const deleteUser = httpsCallable<DeleteUserRequestData>(
    functions,
    'deleteUser',
  )

  async function handleToggleFacility(facilityName: FacilityName) {
    await editUser({ uid, facilityName }).catch((error) =>
      onError(`Erreur : ${error.message}`),
    )
  }

  async function handleDeleteClick() {
    // Demande de confirmation avant la suppression
    if (
      window.confirm(
        t`Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ?`,
      )
    ) {
      // Appel de la fonction de suppression si confirmé
      await deleteUser({ uid })
        .then(() => onClose())
        .catch((error) => onError(`Erreur : ${error.message}`))
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="mx-4 flex w-full max-w-md flex-col gap-4 rounded-lg bg-white p-6 shadow-lg">
        <div className="font-bold">{user.email}</div>
        {user.facilityIds ? (
          <div>
            <ul>
              {Object.keys(user.facilityIds).map((facilityName) => (
                <li
                  className="flex items-baseline justify-between py-1 hover:bg-gray-100"
                  key={facilityName}
                >
                  <span>
                    {!facilityNames.includes(facilityName) && (
                      <TriangleAlert className="mr-2 inline" size={16} />
                    )}
                    {facilityName}
                  </span>
                  <span
                    onClick={() => handleToggleFacility(facilityName)}
                    className="pr-36"
                  >
                    <Trash size={16} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <Trans>Aucun établissement associé</Trans>
          </div>
        )}
        <div className="flex items-baseline justify-between gap-4">
          <select
            className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={newFacilityName}
            onChange={(e) => setNewFacilityName(e.target.value as FacilityName)}
            required
          >
            <option value={NO_FACILITY}>
              <Trans>Ajouter un établissement</Trans>
            </option>
            {facilityNames.map((facilityName) => (
              <option key={facilityName} value={facilityName}>
                {facilityName}
              </option>
            ))}
          </select>
          <button
            onClick={() => handleToggleFacility(newFacilityName)}
            disabled={
              newFacilityName === NO_FACILITY ||
              user.facilityIds?.[newFacilityName] === true
            }
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-50"
          >
            <Trans>Ajouter</Trans>
          </button>
        </div>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            onClick={handleDeleteClick}
          >
            <Trans>Supprimer cet utilisateur</Trans>
          </button>
          <button
            type="button"
            className="rounded bg-gray-500 px-4 py-2 font-bold text-white hover:bg-gray-700"
            onClick={onClose}
          >
            <Trans>Fermer</Trans>
          </button>
        </div>
      </div>
    </div>
  )
}
