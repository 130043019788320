import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import {
  activities,
  activityData,
  ActivityType,
  hiddenCommonRoomsActivities,
} from './utils/activity'

interface Props {
  selectedActivity: ActivityType | undefined
  setSelectedActivity: (activity: ActivityType) => void
  isCommonRoom: boolean
}

export const ActivityLegend: React.FC<Props> = ({
  selectedActivity,
  setSelectedActivity,
  isCommonRoom,
}) => {
  const { _: lingui } = useLingui()

  return (
    <div>
      <div className="text-sm">
        <Trans>Cliquez sur une activité pour la sélectionner</Trans>
      </div>
      <div className="sticky top-0 z-10 mb-4 flex flex-row flex-wrap items-center justify-around gap-3 bg-white bg-opacity-90 py-3">
        {activities.map((activity) => (
          <div
            key={activity}
            onClick={() => setSelectedActivity(activity)}
            className={`cursor-pointer ${selectedActivity === activity ? 'font-bold' : ''} ${isCommonRoom && hiddenCommonRoomsActivities.includes(activity) ? 'opacity-50' : ''}`}
          >
            <span
              className={`rounded-md px-3 py-1 ${activityData[activity].color} mr-2`}
            ></span>
            {lingui(activityData[activity].title)}
          </div>
        ))}
      </div>
    </div>
  )
}
