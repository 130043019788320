import { Trans } from '@lingui/macro'
import { demoFacilityName } from 'common/demo'
import React from 'react'
import { Zones } from 'shared/types/fleet'
import { FacilityName, FirebaseKey } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { zoneSorter } from 'shared/utils/zone'

interface Props {
  zones: Zones
  selectedZone: FirebaseKey | undefined
  setSelectedZone: (_: FirebaseKey | undefined) => void
}

export const ZoneSelector: React.FC<Props> = ({
  zones,
  selectedZone,
  setSelectedZone,
}) => {
  if (isObjectEmpty(zones)) return null

  const ALL = ''

  return (
    <div className="flex flex-row flex-wrap items-baseline justify-center gap-4">
      <label htmlFor="zone">
        <Trans>Zone :</Trans>
      </label>
      <select
        id="zone"
        value={selectedZone}
        className="rounded bg-blue-500 px-4 py-2 text-white focus:border-blue-600 focus:ring-blue-600"
        onChange={(e) => {
          setSelectedZone(e.target.value === ALL ? undefined : e.target.value)
        }}
      >
        <option value={ALL}>
          <Trans>Toutes</Trans>
        </option>
        {Object.entries(zones)
          .sort(zoneSorter)
          .map(([key, zone]) => (
            <option key={key} value={key}>
              {zone.name}
            </option>
          ))}
      </select>
    </div>
  )
}

// TODO Remove
// TODO mettre les zones factices dans DEMO-OSO dans fleet
export function fakeGetFacilityZones(facilityName: FacilityName): Zones {
  if (facilityName === 'BEAUREPAIRE-AFIPH')
    return {
      A: {
        name: 'A',
        order: 1,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'A', to: 'Az' } } },
      },
      B: {
        name: 'B',
        order: 2,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'B', to: 'Bz' } } },
      },
      C: {
        name: 'C',
        order: 3,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'C', to: 'Cz' } } },
      },
      D: {
        name: 'D',
        order: 4,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'D', to: 'Dz' } } },
      },
      E: {
        name: 'E',
        order: 5,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'E', to: 'Ez' } } },
      },
      F: {
        name: 'F',
        order: 6,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'F', to: 'Fz' } } },
      },
      G: {
        name: 'G',
        order: 7,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'G', to: 'Gz' } } },
      },
      H: {
        name: 'H',
        order: 8,
        isHoliday: false,
        regions: { r: { roomRange: { from: 'H', to: 'Hz' } } },
      },
    }

  if (facilityName === demoFacilityName)
    return {
      etage1: {
        name: '1er étage',
        order: 1,
        isHoliday: false,
        regions: { r: { roomRange: { from: '100', to: '199' } } },
      },
      etage2: {
        name: '2ème étage',
        order: 2,
        isHoliday: false,
        regions: { r: { roomRange: { from: '200', to: '299' } } },
      },
    }

  return {}
}
