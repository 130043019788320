import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { HourLabelData } from 'common/types'
import { DateTime } from 'luxon'
import { TimeRange } from 'shared/types/timeRange'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { GraphConfig } from './utils/activity'

interface Props<LabelType extends string> {
  hourValues: HourLabelData<LabelType>
  maxValue?: number
  displayScale?: boolean
  labels: readonly LabelType[]
  selectedLabel: string | undefined
  graphConfig: GraphConfig<LabelType>
  monitoringTimeRange: TimeRange
}

export function DayGraph<LabelType extends string>({
  hourValues,
  maxValue,
  displayScale,
  labels,
  selectedLabel,
  graphConfig,
  monitoringTimeRange,
}: Props<LabelType>) {
  const { _: lingui } = useLingui()
  maxValue ??= Math.max(
    ...Object.values(hourValues).flatMap(
      (labelValues) => Object.values(labelValues ?? {}) as number[],
    ),
  )

  return (
    <div className="flex flex-row overflow-x-auto">
      <div className="flex flex-1 flex-row justify-items-stretch gap-0.5 md:gap-1">
        {displayScale && (
          <div className="text-xs sm:text-sm">
            <div className="flex h-24 flex-col items-end justify-between pr-1 md:pr-2">
              <div>{maxValue}</div>
              <div className="-mb-2">0</div>
            </div>
            <div>&nbsp;</div>
          </div>
        )}
        {Array.from(Array(24).keys()).map((hour) => (
          <div key={hour} className="flex min-w-0 flex-1 flex-col gap-1">
            <div
              className={`flex h-24 flex-row items-end justify-items-stretch lg:px-1 ${
                withinMonitoringRange(hour, monitoringTimeRange)
                  ? 'bg-gray-200'
                  : 'bg-gray-50'
              }`}
            >
              {labels.map((label) => {
                const value = hourValues?.[hour]?.[label] ?? 0
                return selectedLabel === undefined ||
                  selectedLabel === label ? (
                  <div
                    title={lingui(graphConfig[label].title)}
                    key={label}
                    style={{
                      height: `${
                        (95 * value * graphConfig[label].scale) /
                        (maxValue || 1)
                      }%`,
                    }}
                    className={`${graphConfig[label].color} max-h-full flex-1`}
                  />
                ) : null
              })}
            </div>
            <div className="w-full text-center text-xs sm:text-sm">
              <span className="hidden md:block">{t`${hour}h`}</span>
              <span className="md:hidden">{hour}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function withinMonitoringRange(hour: number, timeRange: TimeRange) {
  const hour2Digits = hour.toString().padStart(2, '0')
  // Will use browser's timezone, and the toFormat() function used in
  // isWithinTimeRange() will retrieve the same hour
  const date = DateTime.fromISO(`2020-01-01T${hour2Digits}:00:00`)
  return isWithinTimeRange(date, timeRange)
}
