import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

export const activities = [
  'sleep',
  'tv',
  'speech',
  'steps',
  'complaint',
] as const // order defines precedence

export type ActivityType = (typeof activities)[number]

// Related to live/Rooms, where icons are disabled for common rooms
export const hiddenCommonRoomsActivities: ActivityType[] = [
  'tv',
  'sleep',
] as const

export type GraphConfig<T extends string> = Record<
  T,
  { title: MessageDescriptor; color: string; scale: number }
>

export const activityData: GraphConfig<ActivityType> = {
  sleep: { title: msg`Sommeil`, color: 'bg-purple-300', scale: 1 },
  tv: {
    title: msg`Télévision ou radio`,
    color: 'bg-blue-500',
    scale: 1,
  },
  speech: { title: msg`Parole`, color: 'bg-cyan-300', scale: 2 },
  steps: {
    title: msg`Bruit de pas`,
    color: 'bg-green-500',
    scale: 2,
  },
  complaint: {
    title: msg`Plainte`,
    color: 'bg-red-500',
    scale: 2,
  },
}
